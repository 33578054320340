import React, { useState, useEffect } from "react";

import { Paper, Typography, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Arrow from "../Components/Arrow";
import { Button, Container } from "@mui/material";

import ConnectedWeb3Provider from "../wallet/ConnectedWeb3Provider";
import { contractABI } from "../contracts/contractABI";
import { useTransactionMonitor } from "../hooks/useTransactionMonitor";
import {
  erc20MinterContract,
  erc20MinterAddress,
  web3,
} from "../contracts/ContractProvider";
import Pack from "../Components/Pack";

export default function Mint(props) {
  const [price1, setPrice1] = useState([0.0]);
  const [price5, setPrice5] = useState([0.0]);
  const [price10, setPrice10] = useState([0.0]);
  const [price20, setPrice20] = useState([0.0]);

  const { monitorTransaction } = useTransactionMonitor();

  const mintPacks = async (_amount) => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const web3I = ConnectedWeb3Provider.web3; // use the connected wallet (this is my personal trick, there is probably a better way)
    const NFTWrite = new web3I.eth.Contract(contractABI, erc20MinterAddress); // create a writeable version of the contract

    try {
      // get the cost from the contract so that it's always up to date.
      const amount = await NFTWrite.methods.mintAmountToPrice(_amount).call();

      const estimatedGas = await NFTWrite.methods
        .mint_pack(_amount)
        .estimateGas({ from: props.account, value: amount });
      //console.log(estimatedGas);

      await NFTWrite.methods
        .mint_pack(_amount)
        .send(
          { from: props.account, value: amount, gas: estimatedGas + 10000 },
          (error, txHash) => {
            if (!error) {
              monitorTransaction(error, txHash, async () => { });
            }
          }
        )
        .catch(() => { });
    } catch (error) {
      console.log(error);
    }
  };

  const getPricing = async () => {
    // this can be done read only
    const r1 = await erc20MinterContract.methods.mintAmountToPrice(1).call();
    r1 && setPrice1(web3.utils.fromWei(r1, "ether"));
    const r5 = await erc20MinterContract.methods.mintAmountToPrice(5).call();
    r5 && setPrice5(web3.utils.fromWei(r5, "ether"));
    const r10 = await erc20MinterContract.methods.mintAmountToPrice(10).call();
    r10 && setPrice10(web3.utils.fromWei(r10, "ether"));
    const r20 = await erc20MinterContract.methods.mintAmountToPrice(20).call();
    r20 && setPrice20(web3.utils.fromWei(r20, "ether"));
  };

  useEffect(() => {
    getPricing();
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper elevation={3} sx={{ overflow: "hidden" }}>
        <Grid
          container
          spacing={2}
          sx={{ flexDirection: { sm: "column", md: "row" } }}
        >
          <Grid item xs={12} md={5}>
            <Box sx={{ p: 5 }}>
              <Typography variant="h1">Mint packs</Typography>
              <Typography variant="h3" sx={{ pt: 5 }}>
                Collection
              </Typography>
              {/* <Typography variant="subtitle1" color="secondary" sx={{ pt: 1 }}>
                SHIRYO -{" "}
                {props.isFounder ? (
                  <span style={{ color: "gold" }}>Founders Pack</span>
                ) : (
                  "Base Pack"
                )}
              </Typography> */}
              <Typography variant="subtitle1" color="secondary" sx={{ pt: 1 }}>
                SHIRYO - Base Pack
              </Typography>
              <Typography variant="h3" sx={{ pt: 5 }}>
                Total cards
              </Typography>
              <Typography variant="subtitle1" color="secondary" sx={{ pt: 1 }}>
                150 TO COLLECT
              </Typography>
              <Grid container width="100%" sx={{ pt: 5 }}>
                <Grid item xs={5}>
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                    onClick={() => mintPacks(1)}
                  >
                    MINT 1 PACK
                  </Button>
                </Grid>
                <Grid item xs={7}>
                  <Box
                    sx={{
                      background: "black",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #2B43C2",
                    }}
                  >
                    <Typography variant="subtitle1">{price1} ETH</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container width="100%" sx={{ pt: 3 }}>
                <Grid item xs={5}>
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                    onClick={() => mintPacks(5)}
                  >
                    MINT 5 PACKS
                  </Button>
                </Grid>
                <Grid item xs={7}>
                  <Box
                    sx={{
                      background: "black",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #2B43C2",
                    }}
                  >
                    <Typography variant="subtitle1">{price5} ETH</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container width="100%" sx={{ pt: 3 }}>
                <Grid item xs={5}>
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                    onClick={() => mintPacks(10)}
                  >
                    MINT 10 PACKS
                  </Button>
                </Grid>
                <Grid item xs={7}>
                  <Box
                    sx={{
                      background: "black",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #2B43C2",
                    }}
                  >
                    <Typography variant="subtitle1">{price10} ETH</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid container width="100%" sx={{ pt: 3 }}>
                <Grid item xs={5}>
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                    onClick={() => mintPacks(20)}
                  >
                    MINT 20 PACKS
                  </Button>
                </Grid>
                <Grid item xs={7}>
                  <Box
                    sx={{
                      background: "black",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #2B43C2",
                    }}
                  >
                    <Typography variant="subtitle1">{price20} ETH</Typography>
                  </Box>
                </Grid>
              </Grid>
              {/* {props.isFounder ? (
                <>
                  <Typography
                    color="white"
                    sx={{
                      fontWeight: "bold",
                      pt: 5,
                      textTransform: "uppercase",
                    }}
                  >
                    6 packs will contain enough cards needed to play the
                    Shiryo-Inu game
                  </Typography>

                  <Typography color="secondary" sx={{ py: 3 }}>
                    <span style={{ color: "gold" }}>Congratulations</span> you
                    have been upgraded to the founders pack for free as a thank
                    you for being an early supporter and holding over 25T
                    tokens. The founder’s pack cards will come with a variety of
                    exciting perks which will be disclosed very soon.
                    <br />
                    <br /> If you do not hold at least 25T tokens, the pack you
                    mint will be a base pack. Your packs will be available to be
                    unlocked to reveal your actual cards once we finalise a few
                    more gameplay dynamics.
                    <br />
                    <br /> You can mint as many packs as you like to get a head
                    start on your collection of the entire pack.
                  </Typography>
                </>
              ) : ( */}
              <>
                {/* <Typography
                  color="white"
                  sx={{
                    fontWeight: "bold",
                    pt: 5,
                    textTransform: "uppercase",
                  }}
                >
                  6 packs will contain enough cards needed to play the
                  Shiryo game
                </Typography> */}

                <Typography color="secondary" sx={{ py: 3 }}>
                  {/* Shiryo-Inu are endeavouring to reward our early holders!
                  <br />
                  <br />
                  If you do not hold at least 25T tokens, the pack you mint
                  will be a base pack. Your packs will be available to be
                  unlocked to reveal your actual cards once we finalise a few
                  more gameplay dynamics. <br />
                  <br /> */}
                  You can mint as many packs as you like to get a head start
                  on your collection of the entire pack.
                  <br />
                  <br />
                  <a
                    href="https://swap.shiryo.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#2b43c2" }}
                  >
                    Buy More Shiryo
                  </a>{" "}
                  {/* to unlock the Founders Pack for special perks. */}
                </Typography>
              </>
              {/* )} */}

              {/* <Typography color="secondary" variant="subtitle1" sx={{ fontWeight: "bold", pt: 5 }}>
                EACH PACK CONTAINS 5 CARDS
              </Typography>

              <Grid container alignItems="flex-start" display="flex" flexWrap="nowrap" sx={{ pt: 3 }}>
                <Grid item>
                  <Arrow />{" "}
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" sx={{ pl: 2 }}>
                    2 BEASTS (5% Chance of Alpha Beast)
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="flex-start" display="flex" flexWrap="nowrap">
                <Grid item>
                  <Arrow />{" "}
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" sx={{ pl: 2 }}>
                    2 SPELLS
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="flex-start" display="flex" flexWrap="nowrap">
                <Grid item>
                  <Arrow />{" "}
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" sx={{ pl: 2 }}>
                    1 ELEMENT
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="flex-start" display="flex" flexWrap="nowrap">
                <Grid item>
                  <Arrow />{" "}
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" sx={{ pl: 2 }}>
                    0.1% CHANCE OF A MYTHIC BEAST 1 of 1
                  </Typography>
                </Grid>
              </Grid>
              <Grid container alignItems="flex-start" display="flex" flexWrap="nowrap">
                <Grid item>
                  <Arrow />{" "}
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" sx={{ pl: 2 }}>
                    LIMITED EDITION EARLY-BIRD CARD BACKING
                  </Typography>
                </Grid>
              </Grid> */}
            </Box>
          </Grid>
          {/* <Grid item xs={12} md={7}>
            <Pack model={"./normal.glb"} />
          </Grid> */}
          <Grid
            item
            xs={12}
            md={7}
            sx={{
              // background: props.isFounder
              //   ? "url(../founders_bg.png)"
              //   : "url(../normal_bg.png)",
              background: "url(../Mint_mockup_01.jpeg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              width: "100%",
              minHeight: "500px",
            }}
          ></Grid>
        </Grid>
      </Paper>
      <Grid container>
        <Grid
          sx={{ mt: { xs: 5 }, p: { xs: 3 } }}
          item
          xs={12}
          md={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img
            style={{ width: "75%" }}
            // src={
            //   props.isFounder ? "../two_cards.png" : "../two_normal_cards.png"
            // }
            src={"../Mint_Card_Image_02.png"}
            alt="Cards"
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ p: 3 }}>
          <Stack>
            <Grid container alignItems="center" sx={{ pt: 5, pb: 3 }}>
              <Arrow />
              <Typography variant="h3" sx={{ pl: 2 }}>
                HOW TO CLAIM
              </Typography>
            </Grid>
            <Typography color="secondary">
              You will mint your early-bird card pack which will guarantee you
              early access to the NFT’s with additional perks. You will then be
              able to swap your pack for your cards once the individual cards
              are ready.
            </Typography>
          </Stack>
          <Stack>
            <Grid container alignItems="center" sx={{ pt: 5, pb: 3 }}>
              <Arrow />
              <Typography variant="h3" sx={{ pl: 2 }}>
                WHEN CAN I PLAY?
              </Typography>
            </Grid>
            <Typography color="secondary">
              We are still working on our Play to Earn Game, we are aiming for
              it to go live by the end of December / Early January.
            </Typography>
          </Stack>{" "}
          <Stack>
            <Grid container alignItems="center" sx={{ pt: 5, pb: 3 }}>
              <Arrow />
              <Typography variant="h3" sx={{ pl: 2 }}>
                CAN I SELL MY CARDS?
              </Typography>
            </Grid>
            <Typography color="secondary">
              Yes, we are working on our own marketplace. As we release new
              decks we expect this to be thriving with listings of Card NFT’s
              which are no longer available to be minted.
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
