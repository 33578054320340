import { Grid, Button, Paper, Typography, Box, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { Close } from "@mui/icons-material";

import {
  marketContractAddress,
  cardsContractAddress,
  cardsContract,
  web3,
} from "../contracts/ContractProvider";
import ConnectedWeb3Provider from "../wallet/ConnectedWeb3Provider";

import { useInterval } from "../hooks/useInterval";
import { marketContractABI, cardsContractABI } from "../contracts/contractABI";
import { useTransactionMonitor } from "../hooks/useTransactionMonitor";
import { useNavigate } from "react-router-dom";

export default function MarketCard(props) {
  const [listPrice, setListPrice] = useState(0);
  const [listed, setListed] = useState(Boolean(listPrice));
  const [topOffer, setTopOffer] = useState(0);

  const [approvedForAll, setApprovedForAll] = useState(false);
  const navigate = useNavigate();
  const { monitorTransaction } = useTransactionMonitor();
  const web3I = ConnectedWeb3Provider.web3; // use the connected wallet (this is my personal trick, there is probably a better way)

  const onListItemClicked = async (tokenId) => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const MarketWrite = new web3I.eth.Contract(
      marketContractABI,
      marketContractAddress
    ); // create a writeable version of the contract

    try {
      // get the cost from the contract so that it's always up to date.
      const ERC1155Type = 1;
      const amountTokens = 1;
      let listPriceWei = web3.utils.toWei(listPrice.toString(), "ether");

      //console.log("Listing at: " + listPriceWei);

      const estimatedGas = await MarketWrite.methods
        .createMarketItem(
          cardsContractAddress,
          ERC1155Type,
          tokenId,
          amountTokens,
          listPriceWei,
          "Cards"
        )
        .estimateGas({ from: props.account });
      //console.log(estimatedGas);

      const listOnMarket = await MarketWrite.methods
        .createMarketItem(
          cardsContractAddress,
          ERC1155Type,
          tokenId,
          amountTokens,
          listPriceWei,
          "Cards"
        )
        .send(
          { from: props.account, gas: estimatedGas + 50000 },
          (error, txHash) => {
            if (!error) {
              monitorTransaction(error, txHash, async () => {
                console.log(txHash);
              });
            }
          }
        )
        .catch(() => { });

      listOnMarket.status && navigate("/market/" + listOnMarket.events.MarketItemCreated.returnValues.itemId, { replace: true })
      console.log(listOnMarket)
    } catch (error) {
      console.log(error);
    }



  };

  const unListItem = async (itemId) => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const MarketWrite = new web3I.eth.Contract(
      marketContractABI,
      marketContractAddress
    );

    try {
      await MarketWrite.methods
        .cancelMarketItem(itemId)
        .send({ from: props.account }, (error, txHash) => {
          if (!error) {
            monitorTransaction(error, txHash, async () => {
              //console.log(txHash);
            });
          }
        })
        .catch(() => { });
    } catch (error) {
      console.log(error);
    }
  };

  const checkApproved = async () => {
    const cardsApprovedForAll = await cardsContract.methods
      .isApprovedForAll(props.account, marketContractAddress)
      .call();
    if (cardsApprovedForAll) {
      setApprovedForAll(true);
    }
  };

  const onApproveClicked = async () => {
    const CardsWrite = new web3I.eth.Contract(
      cardsContractABI,
      cardsContractAddress
    ); // create a writeable version of the contract

    try {
      // get the cost from the contract so that it's always up to date.
      const estimatedGas = await CardsWrite.methods
        .setApprovalForAll(marketContractAddress, true)
        .estimateGas({ from: props.account });
      //console.log(estimatedGas);

      await CardsWrite.methods
        .setApprovalForAll(marketContractAddress, true)
        .send(
          { from: props.account, gas: estimatedGas + 50000 },
          (error, txHash) => {
            if (!error) {
              monitorTransaction(error, txHash, async () => {
                //console.log(txHash);
                checkApproved();
              });
            }
          }
        )
        .catch(() => { });
    } catch (error) {
      console.log(error);
    }
  };

  const acceptItemOffer = async (offerId) => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const MarketWrite = new web3I.eth.Contract(
      marketContractABI,
      marketContractAddress
    );
    ////console.log(offerId);
    try {
      await MarketWrite.methods
        .acceptItemOffer(offerId)
        .send({ from: props.account }, (error, txHash) => {
          if (!error) {
            monitorTransaction(error, txHash, async () => {
              //console.log(txHash);
            });
          }
        })
        .catch(() => { });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkApproved();
  }, []);

  return (
    <Grid container spacing={5} sx={{ py: 3 }}>
      <Grid item md={props.item.hasHighestOffer && 6} xs={12}>
        <Paper sx={{ p: 3, background: "rgba(17, 17, 32, 0.7)" }}>
          <Typography variant="h6">
            {props.owner ? "LISTED" : "LIST"} ON MARKETPLACE
          </Typography>

          {!approvedForAll ? (
            <Grid container width="100%" sx={{ pt: 1 }}>
              <Grid item xs={12}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  onClick={() => onApproveClicked()}
                >
                  APPROVE LISTING
                </Button>
              </Grid>
            </Grid>
          ) : (
            <>
              {props.owner ? (
                <Grid container width="100%" sx={{ pt: 1 }}>
                  <Grid item xs={4}>
                    <Button
                      sx={{ width: "100%" }}
                      variant="contained"
                      color="primary"
                      onClick={() => unListItem(props.item.card.itemId)}
                    >
                      UNLIST
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    <Box
                      sx={{
                        position: "relative",
                        background: "black",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #2B43C2",
                      }}
                    >
                      <Typography variant="subtitle1">
                        {web3.utils.fromWei(props.item.card.price)} ETH
                      </Typography>
                      <Close
                        sx={{
                          position: "absolute",
                          right: "8px",
                          color: "#2E3A59",
                        }}
                        onClick={() => unListItem(props.item.card.itemId)}
                        fontSize="12px"
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container width="100%" sx={{ pt: 1 }}>
                  <Grid item xs={4}>
                    <Button
                      sx={{ width: "100%" }}
                      variant="contained"
                      color="primary"
                      onClick={() => onListItemClicked(props.item.number)}
                    >
                      LIST
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    <Box
                      sx={{
                        background: "black",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #2B43C2",
                      }}
                    >
                      <NumberFormat
                        onValueChange={(values) => {
                          setListPrice(values.floatValue);
                        }}
                        className="numberFormat"
                        suffix=" ETH"
                        placeholder="ENTER PRICE"
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Paper>
      </Grid>
      {props.item.hasHighestOffer && (
        <Grid item md={6} xs={12}>
          <Paper sx={{ p: 3, background: "rgba(17, 17, 32, 0.7)" }}>

            <>
              <Stack direction="row">
                <Typography variant="h6">TOP OFFER</Typography>
                <Typography
                  sx={{ ml: 1 }}
                  variant="body2"
                  color="secondary"
                  fontSize="11px"
                >
                  {props.item && props.item.highestOffer.bidder.substr(0, 6)}...
                  {props.item &&
                    props.item.highestOffer.bidder.substr(
                      props.item.highestOffer.bidder &&
                      props.item.highestOffer.bidder.length - 3,
                      3
                    )}
                </Typography>
              </Stack>
              <Grid container width="100%" sx={{ pt: 1 }}>
                <Grid item xs={4}>
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      acceptItemOffer(props.item.highestOffer.offerId)
                    }
                  >
                    ACCEPT
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <Box
                    sx={{
                      background: "black",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #2B43C2",
                    }}
                  >
                    <Typography variant="subtitle1">
                      {parseFloat(
                        web3.utils.fromWei(props.item.highestOffer.offerAmount)
                      ).toFixed(4)}{" "}
                      ETH
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </>

          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
