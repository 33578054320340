import { Stack, Typography, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { web3 } from "../contracts/ContractProvider";
import { rarities } from "../rarities";

const useStyles = makeStyles({
  root: {
    zIndex: 9,
    borderRadius: "10px",
    transition: "background 0s ease-in-out",
    "&:hover": {
      background:
        "url(https://test.app.shiryoinu.com/viewbtn.svg) no-repeat center center / auto, linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.25))",
    },
  },
});

export default function GridItemAvatar({ item, index, isLoaded, market }) {
  const classes = useStyles();
  //console.log(item);
  return (
    <Box className={classes.root}>
      <NavLink
        to={market ? `/market/${item.card.itemId}` : `/avatar/${item.edition}`}
      >
        <Box
          sx={{
            position: "relative",
            border: "1px solid rgba(56,59,77,0.3)",
            textAlign: "center",
            width: "170px",
            height: "237px",
            borderRadius: "10px",
            zIndex: "-9",
          }}
        >
          <img
            src={item.image}
            alt={item.edition}
            style={{
              width: "124px",
              borderRadius: "100%",
              marginTop: "32px",
            }}
          />

          <Stack
            sx={{
              p: 1,
              position: "absolute",
              bottom: "4px",
              left: "4px",
              right: "4px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                sx={{ fontWeight: "bold", fontSize: "10px", color: "#fff" }}
              >
                #{item.edition}
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  color: rarities["mythic"],
                  fontWeight: "bold",
                }}
              >
                MYTHIC
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography sx={{ fontSize: "10px", color: "#93A3AF" }}>

                {market ? web3.utils.fromWei(item.card.price, "ether") + " ETH" : "QTY: 1"}
              </Typography>
              <Typography sx={{ fontSize: "10px", color: "#93A3AF" }}>
                AVATAR
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </NavLink>
    </Box>
  );
}
