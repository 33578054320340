import { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import {
  marketContractAddress,
  marketContract,
  web3,
  founderPackAddress,
  cardsContract,
} from "../contracts/ContractProvider";
import ConnectedWeb3Provider from "../wallet/ConnectedWeb3Provider";
import { marketContractABI } from "../contracts/contractABI";
import { exampleItems } from "../exampleItems";
import GridItemCard from "./GridItemCard";
import { rarities } from "../rarities";
import { HelpOutline } from "@mui/icons-material";
import NumberFormat from "react-number-format";

import { useTransactionMonitor } from "../hooks/useTransactionMonitor";
import MarketCard from "./MarketCard";
import MarketAvatar from "./MarketAvatar";
import MarketFPack from "./MarketFPack";
import MarketNPack from "./MarketNPack";
import GridItemAvatar from "./GridItemAvatar";

export default function MarketItem(props) {
  const [item, setItem] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [owner, setOwner] = useState("");
  const [userBalanceOfCard, setUserBalanceOfCard] = useState(0);
  const [offerAmount, setOfferAmount] = useState(0);
  const [youMayAlsoLike, setYouMayAlsoLike] = useState([]);
  const { monitorTransaction } = useTransactionMonitor();
  const web3I = ConnectedWeb3Provider.web3;
  const navigate = useNavigate();
  let { id } = useParams();

  const buyItem = async () => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;
    const MarketWrite = new web3I.eth.Contract(
      marketContractABI,
      marketContractAddress
    );

    //console.log(item);

    try {
      const buyItem = await MarketWrite.methods
        .createMarketSale(item.card.itemId)
        .send(
          {
            from: props.account,
            value: item.card.price,
          },
          (error, txHash) => {
            if (!error) {
              monitorTransaction(error, txHash, async () => {
                console.log(txHash);
              });
            }
          }
        );
      console.log(buyItem);

      buyItem.status &&
        buyItem.events.MarketSaleCreated.returnValues.category === "Packs" &&
        buyItem.events.MarketSaleCreated.returnValues.tokenContract ===
        founderPackAddress &&
        navigate("/my-packs/founders", { replace: true });

      buyItem.status &&
        buyItem.events.MarketSaleCreated.returnValues.category === "Packs" &&
        buyItem.events.MarketSaleCreated.returnValues.tokenContract !==
        founderPackAddress &&
        navigate("/my-packs/base", { replace: true });

      buyItem.status &&
        buyItem.events.MarketSaleCreated.returnValues.category === "Cards" &&
        navigate(
          "/card/" + buyItem.events.MarketSaleCreated.returnValues.tokenId,
          { replace: true }
        );

      buyItem.status &&
        buyItem.events.MarketSaleCreated.returnValues.category === "Avatars" &&
        navigate(
          "/avatar/" + buyItem.events.MarketSaleCreated.returnValues.tokenId,
          { replace: true }
        );

      // buyItem.status && navigate("/market/" + buyItem.events.MarketItemCreated.returnValues.itemId, { replace: true })
    } catch (error) {
      console.log(error);
    }
  };

  const makeAnOffer = async () => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;
    const MarketWrite = new web3I.eth.Contract(
      marketContractABI,
      marketContractAddress
    );

    const offerAmountWei = web3.utils.toWei(offerAmount.toString());

    try {
      await MarketWrite.methods.makeItemOffer(item.card.itemId).send(
        {
          from: props.account,
          value: offerAmountWei,
        },
        (error, txHash) => {
          if (!error) {
            monitorTransaction(error, txHash, async () => {
              console.log(txHash);
            });
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCard = async () => {
    const marketItem = await marketContract.methods.idToMarketItem(id).call();

    marketItem && setOwner(marketItem.seller);

    if (marketItem.category === "Avatars") {
      fetch(`https://api.avatars.shiryoinu.com/${marketItem.tokenId}`)
        .then((res) => res.json())
        .then(async (result) => {
          if (result.error !== 1) {
            result.card = marketItem;
            result.category = marketItem.category;

            const itemOffers = await marketContract.methods
              .getItemOffers(marketItem.itemId)
              .call();

            ////console.log(itemOffers);
            result.hasHighestOffer = false;
            if (itemOffers.length > 0) {
              result.hasHighestOffer = true;

              const highestOffer = itemOffers.reduce((prev, current) =>
                prev.offerAmount > current.offerAmount ? prev : current
              );

              //console.log(highestOffer);
              result.highestOffer = highestOffer;
            }

            setItem(result);
            setOfferAmount(web3.utils.fromWei(result.card.price) * 0.9);
            setIsLoaded(true);
          }
        });
    } else if (marketItem.category === "Cards") {
      fetch(`https://api.cards.shiryo.com/card/${marketItem.tokenId}`)
        .then((res) => res.json())
        .then(async (result) => {
          if (result.error !== 1) {
            result.card = marketItem;
            result.category = marketItem.category;

            const itemOffers = await marketContract.methods
              .getItemOffers(marketItem.itemId)
              .call();

            ////console.log(itemOffers);
            result.hasHighestOffer = false;
            if (itemOffers.length > 0) {
              result.hasHighestOffer = true;

              const highestOffer = itemOffers.reduce((prev, current) =>
                prev.offerAmount > current.offerAmount ? prev : current
              );

              //console.log(highestOffer);
              result.highestOffer = highestOffer;
            }

            if (props.account) {
              const userBalanceOf = await cardsContract.methods
                .balanceOf(props.account, marketItem.tokenId)
                .call();
              if (userBalanceOf) setUserBalanceOfCard(userBalanceOf);
            }
            console.log(result)
            setItem(result);
            setOfferAmount(web3.utils.fromWei(result.card.price) * 0.9);
            setIsLoaded(true);
          }
        });
    } else {
      let result = {};
      result.category = marketItem.category;
      result.card = marketItem;

      result.type =
        marketItem.tokenContract === founderPackAddress ? "founders" : "base";
      result.description =
        result.card.amount + " Shiryo " + result.type + " packs";
      setOfferAmount(web3.utils.fromWei(result.card.price) * 0.9);
      setItem(result);
      setIsLoaded(true);
    }
  };

  const fetchYouMayAlsoLike = async () => {
    const marketItems = await marketContract.methods.getMarketItems().call();

    marketItems &&
      marketItems.map((marketItem) => {
        if (marketItem.category === "Avatars") {
          fetch(`https://api.avatars.shiryoinu.com/${marketItem.tokenId}`)
            .then((res) => res.json())
            .then((result) => {
              if (result.error !== 1) {
                result.category = marketItem.category;
                result.card = marketItem;

                if (marketItem.itemId !== id) {
                  setYouMayAlsoLike((youMayAlsoLike) => [
                    ...youMayAlsoLike,
                    result,
                  ]);
                }
              }
            });
        } else if (marketItem.category === "Cards") {
          fetch(`https://api.cards.shiryo.com/${marketItem.tokenId}.json`)
            .then((res) => res.json())
            .then((result) => {
              if (result.error !== 1) {
                result.category = marketItem.category;
                result.card = marketItem;

                if (marketItem.itemId !== id) {
                  setYouMayAlsoLike((youMayAlsoLike) => [
                    ...youMayAlsoLike,
                    result,
                  ]);
                }
              }
            });
        }
        if (youMayAlsoLike.length === 5) return;
      });
  };

  useEffect(() => {
    fetchCard();
    fetchYouMayAlsoLike();

    return () => {
      setYouMayAlsoLike([]);
    };
  }, [id]);


  console.log(item)
  return (
    isLoaded &&
    item && (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button variant="outlinedRounded" component={NavLink} to="/market">
              VIEW ALL
            </Button>
          </Grid>
          <Grid item>
            <Button variant="containedRounded" component={NavLink} to="/market">
              BUY MORE
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>

            {
              (item.animation_url) ?
                <video autoPlay loop poster={`https://s3.eu-west-1.amazonaws.com/cards.shiryo.com/${item.number}.png`} style={{
                  width: "100%",
                  borderRadius: item.category === "Avatars" ? "100%" : "10px",
                }}>
                  <source src={item.animation_url} type="video/webm" />
                </video>
                :
                <img
                  src={
                    item.category === "Cards"
                      ? `https://s3.eu-west-1.amazonaws.com/cards.shiryo.com/${item.card.tokenId}.png`
                      : item.category === "Avatars"
                        ? `https://avatars.shiryoinu.com/${item.card.tokenId}.png`
                        : `../${item.type}pack.png`
                  }
                  alt={item["Card name"]}
                  style={{
                    width: item.category === "Packs" ? "50%" : "100%",
                    borderRadius: item.category === "Avatars" ? "100%" : "10px",

                  }}
                />
            }


            {userBalanceOfCard > 0 ? (
              <Stack textAlign="center" sx={{ mt: 2 }}>
                <Typography sx={{ color: "#46E168", fontWeight: "bold" }}>
                  IN YOUR COLLECTION
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {userBalanceOfCard + " Currently Owned"}
                </Typography>
              </Stack>
            ) : (
              <Stack textAlign="center" sx={{ mt: 2 }}>
                <Typography sx={{ color: "#2B43C2", fontWeight: "bold" }}>
                  NOT IN YOUR COLLECTION
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {"0 Currently Owned"}
                </Typography>
              </Stack>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper
              sx={{ borderRadius: "10px", background: "rgba(23, 24, 40, 1)" }}
            >
              <Stack>
                <Stack
                  sx={{
                    py: 3,
                    px: { sm: 7, xs: 3 },
                    background:
                      "url('../top.png') no-repeat center center / cover",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {item.category === "Avatars"
                        ? "#" + item.edition
                        : item.category === "Cards"
                          ? item.card_name.toUpperCase()
                          : "SHIRYO"}
                    </Typography>
                    {item.category === "Cards" ? (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: rarities[item.rarity.toLowerCase()],
                        }}
                      >
                        {item.rarity.toUpperCase()}
                      </Typography>
                    ) : item.category === "Avatars" ? (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: rarities["mythic"],
                        }}
                      >
                        MYTHIC
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color:
                            item.type === "base"
                              ? rarities["common"]
                              : rarities["legendary"],
                        }}
                      >
                        {item.type.toUpperCase()}
                      </Typography>
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {item.category === "Avatars" ||
                      item.category === "Packs" ? (
                      <Typography variant="body2" sx={{ color: "#93A3AF" }}>
                        QTY:{" "}
                        {item.category === "Avatars" ? "1" : item.card.amount}
                      </Typography>
                    ) : (
                      <Typography variant="body2" sx={{ color: "#93A3AF" }}>
                        {item.frequencey}
                        <Tooltip title="Frequency" placemenet="top-start">
                          <HelpOutline
                            sx={{
                              marginBottom: "-2px",
                              marginLeft: "2px",
                            }}
                            fontSize="14px"
                          />
                        </Tooltip>
                      </Typography>
                    )}
                    <Typography variant="body2" sx={{ color: "#93A3AF" }}>
                      {item.category.toUpperCase()}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack sx={{ px: { sm: 7, xs: 3 }, py: 3 }}>
                  <Stack direction="row">
                    <Stack>
                      <img
                        style={{
                          height: 34,
                          width: 34,
                          marginRight: 18,
                        }}
                        src="https://app.shiryo.com/wolf_round.png"
                        alt={item.card.seller}
                      />
                    </Stack>
                    <Stack>
                      <Typography variant="playSubtitle">LISTED BY</Typography>
                      {owner === props.account ? (
                        <Typography variant="body2" color="text.secondary">
                          YOU
                        </Typography>
                      ) : (
                        <Typography variant="body2" color="text.secondary">
                          {item.card.seller.substr(0, 6)}...
                          {item.card.seller.substr(
                            item.card.seller.length - 3,
                            3
                          )}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                  {owner === props.account ? (
                    <>
                      {item.category === "Cards" ? (
                        <MarketCard
                          owner={true}
                          item={item}
                          account={props.account}
                        />
                      ) : item.category === "Avatars" ? (
                        <MarketAvatar
                          owner={true}
                          item={item}
                          account={props.account}
                        />
                      ) : (
                        <>
                          {item.card.tokenContract === founderPackAddress ? (
                            <MarketFPack
                              owner={true}
                              item={item}
                              account={props.account}
                            />
                          ) : (
                            <MarketNPack
                              owner={true}
                              item={item}
                              account={props.account}
                            />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <Grid container spacing={5} sx={{ py: 3 }}>
                      <Grid item md={6} xs={12}>
                        <Paper
                          sx={{
                            p: 3,
                            background: "rgba(17, 17, 32, 0.7)",
                            cursor: !props.account ? "not-allowed" : "unset",
                          }}
                        >
                          <Typography variant="h6">
                            CURRENT PRICE TO BUY
                          </Typography>
                          <Grid container width="100%" sx={{ pt: 1 }}>
                            <Grid item xs={4}>
                              <Button
                                sx={{
                                  width: "100%",
                                  cursor: !props.account
                                    ? "not-allowed"
                                    : "unset",
                                }}
                                variant="contained"
                                color="primary"
                                onClick={() => buyItem()}
                              >
                                BUY
                              </Button>
                            </Grid>
                            <Grid item xs={8}>
                              <Box
                                sx={{
                                  background: "black",
                                  height: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  border: "1px solid #2B43C2",
                                }}
                              >
                                <Typography variant="subtitle1">
                                  {web3.utils.fromWei(item.card.price)} ETH
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Paper
                          sx={{ p: 3, background: "rgba(17, 17, 32, 0.7)" }}
                        >
                          <Stack>
                            <Typography variant="h6">MAKE AN OFFER</Typography>
                          </Stack>
                          <Grid
                            container
                            width="100%"
                            sx={{
                              pt: 1,
                              cursor: !props.account ? "not-allowed" : "unset",
                            }}
                          >
                            <Grid item xs={4}>
                              <Button
                                sx={{
                                  width: "100%",
                                  cursor: !props.account
                                    ? "not-allowed"
                                    : "unset",
                                }}
                                variant="contained"
                                color="primary"
                                onClick={() => makeAnOffer(item.card.itemId)}
                              >
                                BID
                              </Button>
                            </Grid>
                            <Grid item xs={8}>
                              <Box
                                sx={{
                                  background: "black",
                                  height: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  border: "1px solid #2B43C2",
                                }}
                              >
                                <NumberFormat
                                  onValueChange={(values) => {
                                    if (values.floatValue)
                                      setOfferAmount(values.floatValue);
                                  }}
                                  value={offerAmount.toFixed(4)}
                                  className="numberFormat"
                                  suffix=" ETH"
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  )}
                  <Typography variant="playSubtitle">ABOUT</Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ py: 1 }}
                  >
                    {item.description}
                  </Typography>
                  {/* <Divider sx={{ my: 3 }} />
                  <Typography variant="playSubtitle">PROPERTIES</Typography>

                  <Grid
                    container
                    spacing={1}
                    justifyContent="space-between"
                    sx={{ py: 1 }}
                  >
                    <Grid item xs={12} md={4}>
                      <Paper
                        variant="outlined"
                        sx={{ background: "none", p: 2, height: "100%" }}
                      >
                        <Typography>{item.element}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          10% of beasts have this trait
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Paper
                        variant="outlined"
                        sx={{ background: "none", p: 2, height: "100%" }}
                      >
                        <Typography>{item.type} Beast</Typography>
                        <Typography variant="body2" color="text.secondary">
                          4% of beasts have this trait
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Paper
                        variant="outlined"
                        sx={{ background: "none", p: 2, height: "100%" }}
                      >
                        <Typography>{item.rarity} Rarity</Typography>
                        <Typography variant="body2" color="text.secondary">
                          0.1% of beasts have this trait
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid> */}
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">YOU MAY ALSO LIKE</Typography>
          </Grid>
          <Grid item>
            <Button variant="outlinedRounded" component={NavLink} to="/market">
              VIEW ALL
            </Button>
          </Grid>
        </Grid>
        {youMayAlsoLike && (
          <>
            <Divider sx={{ my: 3 }} />
            <Grid
              container
              spacing={2}
              sx={{ py: 3 }}
              display="grid"
              gridTemplateColumns="repeat(auto-fill, minmax(170px, 1fr))"
            >
              {youMayAlsoLike.map(
                (youMayAlsoLike, index) =>
                  index < 6 && (
                    <Grid key={index} item>
                      {youMayAlsoLike.category === "Cards" ? (
                        <GridItemCard
                          item={youMayAlsoLike}
                          index={index}
                          market={true}
                        />
                      ) : (
                        <GridItemAvatar
                          item={youMayAlsoLike}
                          index={index}
                          market={true}
                        />
                      )}
                    </Grid>
                  )
              )}
            </Grid>
          </>
        )}
      </Container>
    )
  );
}
