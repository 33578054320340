import React, { useState, useEffect } from "react";

import {
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  founderPackAddress,
  marketContractAddress,
  marketContract,
  web3,
} from "../contracts/ContractProvider";
import ConnectedWeb3Provider from "../wallet/ConnectedWeb3Provider";
import { marketContractABI } from "../contracts/contractABI";
import Filter from "../Components/Filter";
import OfferCard from "../Components/OfferCard";
import OfferAvatar from "../Components/OfferAvatar";
import OfferPacks from "../Components/OfferPacks";
import ConnectWallet from "../Components/ConnectWallet";
import { useTransactionMonitor } from "../hooks/useTransactionMonitor";

const SORTOPTIONS = ["Price: Low to High", "Price: High to Low"];

export default function MyOffers(props) {
  const [loading, setLoading] = useState(true);

  const [query, setQuery] = useState("");
  const [filterParam, setFilterParam] = useState("all");

  const [searchParam] = useState(["card_name", "description"]);

  const [error, setError] = useState(null);
  const [items, setItems] = useState(null);

  const { monitorTransaction } = useTransactionMonitor();
  const web3I = ConnectedWeb3Provider.web3;

  function search(items) {
    return items.filter((item) => {
      if (item.category == filterParam) {
        return searchParam.some((newItem) => {
          if (item[newItem] !== undefined)
            return (
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1
            );
        });
      } else if (filterParam == "all") {
        return searchParam.some((newItem) => {
          if (item[newItem] !== undefined)
            return (
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1
            );
        });
      }
    });
  }

  const cancelOffer = async (offerId) => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const MarketWrite = new web3I.eth.Contract(
      marketContractABI,
      marketContractAddress
    );

    try {
      await MarketWrite.methods
        .canceItemOffer(offerId)
        .send({ from: props.account }, (error, txHash) => {
          if (!error) {
            monitorTransaction(error, txHash, async () => {
              setItems(items.filter((item) => item.card.offerId !== offerId));
              //console.log(txHash);
            });
          }
        })
        .catch(() => { });
    } catch (error) {
      console.log(error);
    }
  };

  const buyForListPrice = async (item) => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const MarketWrite = new web3I.eth.Contract(
      marketContractABI,
      marketContractAddress
    );
    //console.log(item);
    try {
      const buy = await MarketWrite.methods
        .createMarketSale(item.marketItem.itemId)
        .send(
          {
            from: props.account,
            value: item.marketItem.price,
          },
          (error, txHash) => {
            if (!error) {
              monitorTransaction(error, txHash, async () => {
                setItems(items.filter((i) => i.card.itemId !== item.marketItem.itemId));
                //console.log(txHash);
              });
            }
          }
        )
        .catch(() => { });
      console.log(buy)
    } catch (error) {
      console.log(error);
    }
  };

  const handleSortBy = (sortBy) => {
    switch (sortBy) {
      case "Price: Low to High":
        const lowToHigh = [...items].sort((a, b) => {
          return a.card.price - b.card.price;
        });
        setItems(lowToHigh);

        break;
      case "Price: High to Low":
        const highToLow = [...items].sort((a, b) => {
          return b.card.price - a.card.price;
        });
        setItems(highToLow);

        break;
      default:
        break;
    }
  };

  const fetchItems = async () => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    var marketOffers = await marketContract.methods
      .getOffersByBidder(props.account)
      .call();
    console.log(marketOffers)
    setItems([]);
    marketOffers.map(async (offer) => {
      const marketItem = await marketContract.methods
        .idToMarketItem(offer.itemId)
        .call();

      if (marketItem.category === "Avatars") {
        fetch(`https://api.avatars.shiryoinu.com/${marketItem.tokenId}`)
          .then((res) => res.json())
          .then(async (result) => {
            if (result.error !== 1) {
              result.card = offer;
              result.category = marketItem.category;
              /*
            const marketItem = await marketContract.methods
              .idToMarketItem(offer.itemId)
              .call();
            */
              result.marketItem = marketItem;

              !result.marketItem.isSold && !result.marketItem.cancelled && setItems((items) => [...items, result]);
            }
          });
      } else if (marketItem.category === "Cards") {
        fetch(`https://api.cards.shiryo.com/${marketItem.tokenId}.json`)
          .then((res) => res.json())
          .then(async (result) => {
            if (result.error !== 1) {
              result.card = offer;
              result.category = marketItem.category;
              /*
              const marketItem = await marketContract.methods
                .idToMarketItem(offer.itemId)
                .call();
              */
              result.marketItem = marketItem;
              //console.log(result);
              !result.marketItem.isSold && !result.marketItem.cancelled && setItems((items) => [...items, result]);
            }
          });
      } else {
        let result = {};
        result.category = marketItem.category;
        result.card = offer;

        result.type = marketItem.tokenContract === founderPackAddress ? "founders" : "base";
        result.description = marketItem.amount + " Shiryo " + result.type + " packs"
        result.marketItem = marketItem;
        !result.marketItem.isSold && !result.marketItem.cancelled && setItems((items) => [...items, result]);

      }


    });
  };

  useEffect(() => {
    try {
      fetchItems();
    } catch (e) {
      setError(e);
    }
    return () => (
      setItems([])
    )
  }, [props.account]);

  return (
    <Container maxWidth="lg" sx={{ my: 5 }}>
      {props.account ? (
        <>
          <Filter
            title="MY OFFERS"
            setQuery={setQuery}
            filterParam={filterParam}
            setFilterParam={setFilterParam}
            setSortBy={handleSortBy}
            sortOptions={SORTOPTIONS}
          />
          {error && error}
          {items && items.length === 0 && (
            <Typography textAlign="center" sx={{ mt: 5 }}>
              No offers yet
            </Typography>
          )}

          {items &&
            search(items).map((item, index) => {
              return (
                <Paper
                  key={index}
                  sx={{
                    my: 3,
                    background: "rgba(20, 22, 40, 0.8)",
                    border: "1px solid rgba(56,59,77,0.3)",
                    borderRadius: "10px",
                  }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item sx={{ zIndex: 9999 }} xs={12} md={2}>
                      {item.category === "Cards" ? (
                        <OfferCard
                          item={item}
                          index={index}
                          isLoaded={true}
                          market={true}
                          price={web3.utils.fromWei(item.marketItem.price)}
                        />
                      ) : item.category === "Avatars" ? (
                        <OfferAvatar
                          item={item}
                          index={index}
                          isLoaded={true}
                          market={true}
                          price={web3.utils.fromWei(item.marketItem.price)}
                        />
                      ) : <OfferPacks item={item}
                        index={index}
                        isLoaded={true}
                        market={true}
                      />}
                    </Grid>
                    <Grid
                      item
                      sx={{
                        py: { xs: 3, md: 3 },
                        px: { xs: 3, md: 5 },
                        width: "100%",
                      }}
                      xs={12}
                      md={10}
                    >
                      <Stack>
                        <Typography variant="playSubtitle">ABOUT</Typography>
                        {item.description > 250 ? (
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ py: 1 }}
                          >
                            {item.description.substring(0, 250)}...
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ py: 1 }}
                          >
                            {item.description}
                          </Typography>
                        )}
                        <Paper
                          sx={{
                            background: "rgba(19, 19, 34, 1)",
                            p: { sm: 3, xs: 0 },
                            mt: 3,
                          }}
                        >
                          <Grid container justifyContent="space-between">
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                LIST PRICE:
                              </Typography>
                              <Typography variant="body2">
                                {item.marketItem.price &&
                                  web3.utils.fromWei(item.marketItem.price)}{" "}
                                ETH
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                YOUR OFFER:
                              </Typography>
                              {item.card.offerAmount ? (
                                <Typography variant="body2">
                                  {item.card.offerAmount &&
                                    parseFloat(
                                      web3.utils.fromWei(
                                        item.card.offerAmount
                                      )
                                    ).toFixed(4)}{" "}
                                  ETH
                                </Typography>
                              ) : (
                                <Typography variant="body2">-</Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Grid
                                container
                                direction="row"
                                spacing={1}
                                justifyContent="flex-end"
                                sx={{ mt: { xs: 3, md: 0 } }}
                              >
                                <Grid
                                  item
                                  sx={{ width: { xs: "100%", md: "auto" } }}
                                >
                                  <Button
                                    variant="containedRounded"
                                    sx={{
                                      width: { xs: "100%", md: "auto" },
                                    }}
                                    onClick={() => buyForListPrice(item)}
                                  >
                                    BUY FOR LIST PRICE
                                  </Button>
                                </Grid>
                                <Grid
                                  item
                                  sx={{ width: { xs: "100%", md: "auto" } }}
                                >
                                  <Button
                                    variant="outlinedRounded"
                                    sx={{ width: { xs: "100%", md: "auto" } }}
                                    onClick={() =>
                                      cancelOffer(item.card.offerId)
                                    }
                                  >
                                    CANCEL OFFER
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
        </>
      ) : (
        <ConnectWallet
          subtitle="TO VIEW YOUR OFFERS"
          walletModal={props.walletModal}
          setWalletModal={props.setWalletModal}
        />
      )}
    </Container>
  );
}
