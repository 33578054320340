import React, { useState, useEffect } from "react";
import "./App.css";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { Box, Toolbar, Container } from "@mui/material";
import Navigation from "./Navigation/Navigation";
import Dashboard from "./Pages/Dashboard";
import Marketplace from "./Pages/Marketplace";
import MyPacks from "./Pages/MyPacks";
import PackBridge from "./Pages/PackBridge";
import Play from "./Pages/Play";
import Mint from "./Pages/Mint";
import Farm from "./Pages/Farm";
import Profile from "./Pages/Profile";
import Avatars from "./Pages/Avatars";
import MyOffers from "./Pages/MyOffers";
import ViewCard from "./Components/ViewCard";
import ViewAvatar from "./Components/ViewAvatar";
import ViewPack from "./Components/ViewPack";
import MarketItem from "./Components/MarketItem";
import Cookies from "js-cookie";

import useContract from "./hooks/useContractOperations";
import { useInterval } from "./hooks/useInterval";
import {
  ChainId,
  baseTokenContract,
  erc20MinterContract,
} from "./contracts/ContractProvider";
import TransactionQueueSize from "./hooks/TransactionQueueSize";
import MyCollection from "./Pages/MyCollection";
import MyListings from "./Pages/MyListings";

export default function App() {
  const [account, setAccount] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(undefined);
  const [walletModal, setWalletModal] = useState(false);
  const [wrongNetwork, setWrongNetwork] = React.useState(false);
  const { getNetwork, getAccountInfo, isWeb3Available } = useContract();
  const [queueSize, setQueueSize] = useState(false);
  const [isFounder, setIsFounder] = useState(false);
  const [founderChecked, setFounderChecked] = useState(false);

  const CONTRACT_POLLING_INTERVAL = 3000; // millis

  if (window.ethereum !== undefined) {
    window.ethereum.on("accountsChanged", function (accounts) {
      setAccount(accounts[0]);
    });

    window.ethereum.on("networkChanged", function (networkId) {
      if (networkId.toString() === ChainId.toString()) {
        setWrongNetwork(false);
      } else {
        setWrongNetwork(true);
      }
    });
  }

  useInterval(() => {
    try {
      // getCurrentNetwork();
      // getAccount();
      setQueueSize(TransactionQueueSize.size);
      account && getIsFounder();

      const sa = Cookies.get("current-avatar");
      if (sa !== undefined) {
        const sap = JSON.parse(sa);
        setSelectedAvatar(sap);
      }

    } catch (error) {
      console.log(error);
    }
  }, CONTRACT_POLLING_INTERVAL);

  async function getAccount() {
    const isWeb3 = await isWeb3Available();
    if (isWeb3) {
      const currentAccount = await getAccountInfo();
      ////console.log("currentAccount " + currentAccount);

      if (currentAccount === undefined) {
        setAccount(null);
      }

      if (currentAccount !== account) {
        setAccount(currentAccount);
      }
    } else {
      setAccount(null);
    }

    const sa = Cookies.get("current-avatar");
    if (sa !== undefined) {
      const sap = JSON.parse(sa);
      setSelectedAvatar(sap);
    }
  }

  // async function getCurrentNetwork() {
  //   const network = await getNetwork();
  //   ////console.log(network);
  //   if (network !== ChainId && network !== -1 && network !== undefined) {
  //     ////console.log(network);
  //     setWrongNetwork(true);
  //   } else {
  //     setWrongNetwork(false);
  //   }
  // }

  async function getIsFounder() {
    if (founderChecked) return;
    try {
      let balance = await baseTokenContract.methods.balanceOf(account).call();
      let minAmount = await erc20MinterContract.methods
        .minimumFounderAmount()
        .call();

      ////console.log("balance " + balance);
      ////console.log("minAmount " + minAmount);

      if (parseInt(balance * 10 ** -9) > parseInt(minAmount * 10 ** -9)) {
        setIsFounder(true);
      } else {
        setIsFounder(false);
      }
      setFounderChecked(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    // getCurrentNetwork();
    getAccount();
    account && getIsFounder();
  }, []);

  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        <Navigation
          account={account}
          selectedAvatar={selectedAvatar}
          wrongNetwork={wrongNetwork}
          queueSize={queueSize}
          walletModal={walletModal}
          setWalletModal={setWalletModal}
        />
        <Box
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Routes>
            <Route path={"/farm"} element={<Farm account={account} />} />
            <Route path={"/play"} element={<Play account={account} />} />
            <Route
              path={"/avatars"}
              element={
                <Avatars account={account} selectedAvatar={selectedAvatar} />
              }
            />
            <Route
              path={"/profile"}
              element={
                <Profile account={account} selectedAvatar={selectedAvatar} />
              }
            />

            <Route exact path="/" element={<Dashboard account={account} />} />
            <Route
              path={"/dashboard"}
              element={<Dashboard account={account} />}
            />
            <Route
              path={"/market"}
              element={<Marketplace account={account} />}
            />

            <Route
              path={"/my-collection"}
              element={
                <MyCollection
                  account={account}
                  walletModal={walletModal}
                  setWalletModal={setWalletModal}
                />
              }
            />
            <Route
              path={"/my-offers"}
              element={
                <MyOffers
                  account={account}
                  walletModal={walletModal}
                  setWalletModal={setWalletModal}
                />
              }
            />
            <Route
              path={"/my-listings"}
              element={
                <MyListings
                  account={account}
                  walletModal={walletModal}
                  setWalletModal={setWalletModal}
                />
              }
            />
            <Route path={"/my-packs"} element={<MyPacks account={account} />} />
            <Route path={"/pack-bridge"} element={<PackBridge account={account} />} />

            <Route
              path={"/mint"}
              element={<Mint account={account} isFounder={isFounder} />}
            />
            <Route
              path={"/card/:id"}
              element={<ViewCard account={account} />}
            />
            <Route
              path={"/avatar/:id"}
              element={<ViewAvatar account={account} />}
            />
            <Route
              path={"/my-packs/:type"}
              element={<ViewPack account={account} />}
            />
            <Route
              path={"/market/:id"}
              element={<MarketItem account={account} />}
            />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
}
