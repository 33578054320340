import Web3 from "web3";
import { contractABI, IERC20, RouterABI, FarmABI, AvatarsABI , cardsContractABI, marketContractABI, } from "./contractABI";
import { Token, WETH, Fetcher, Route } from "@uniswap/sdk";
import { ethers } from "ethers";

// Use the mainnet
const network = "homestead";

const infuraProjectId = "9765d565a2d845a18c3c13cf92209d60";
const ethersProvider = new ethers.providers.InfuraProvider(network, infuraProjectId);

export const httpRPCEndpoint = "https://mainnet.infura.io/v3/9765d565a2d845a18c3c13cf92209d60";

export const rpcEndpoint = "wss://mainnet.infura.io/ws/v3/9765d565a2d845a18c3c13cf92209d60";
export const web3 = new Web3(rpcEndpoint);

export const Network = "ETH Mainnet";
export const ChainId = 1;
export const NetworkInstructions = "https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain";

export const founderPackAddress = "0x23427Dea2dd52B13630f2757FF2cD734C5C42174";
export const founderPackContract = new web3.eth.Contract(IERC20, founderPackAddress);

export const normalPackAddress = "0x773D6E3e85Ed29D2C40C42f7cbc8aD3d4dDBeef0";
export const normalPackContract = new web3.eth.Contract(IERC20, normalPackAddress);

export const erc20MinterAddress = "0xad9BB8Ed8D0c14c4fae2504D82D8855b4A8393B3";
export const erc20MinterContract = new web3.eth.Contract(contractABI, erc20MinterAddress);

/// mainnet
export const baseTokenContractAddress = "0x1E2F15302B90EddE696593607b6bD444B64e8F02";
export const ethWeb3 = new Web3("wss://mainnet.infura.io/ws/v3/9765d565a2d845a18c3c13cf92209d60");
export const baseTokenContract = new ethWeb3.eth.Contract(IERC20, baseTokenContractAddress);

export const RouterAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
export const RouterContract = new web3.eth.Contract(RouterABI, RouterAddress);

// farm
export const farmContractAddress = "0x140A330d37D05d6E3ba4B6A9A3E25Da209890C20";
export const farmContract = new web3.eth.Contract(FarmABI, farmContractAddress);
export const stakedTokenContractAddress = "0x1E2F15302B90EddE696593607b6bD444B64e8F02";
export const stakedTokenContract = new web3.eth.Contract(IERC20, stakedTokenContractAddress);
export const stakedLPContractAddress = "0xe6e1F4F9b0303Ca3878A110061C0Ec9b84fddD03";
export const stakedLPContract = new web3.eth.Contract(IERC20, stakedLPContractAddress);

// avatars
export const avatarsContractAddress = "0x69e3fd31d528E6Ca6Cc6E5fF767138Decc073091";
export const avatarsContract = new web3.eth.Contract(AvatarsABI, avatarsContractAddress);

export const cardsContractAddress =
  "0x0411f0FF4105fdc075E5e1BFDAb47183C1B9df78";
export const cardsContract = new web3.eth.Contract(
  cardsContractABI,
  cardsContractAddress
);

// market contract
export const marketContractAddress =
  "0x11ce3c5ECfA4cC788eaa94157a63a1ca4863A90c";
export const marketContract = new web3.eth.Contract(
  marketContractABI,
  marketContractAddress
);

export const bridgeContractAddress =
  "0x3CB6325f22E48a346E2Fa3C383BC89Cb41b82480";


export const getTokenPriceInDollars = async (tokenAddress) => {
  let tokenInUSD = 0;

  try {
    const response = await fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=ETH,USD");
    const tokenInfo = await response.json();
    const ETHPrice = tokenInfo.USD;
    const SHIRYOINU = new Token(1, baseTokenContractAddress, 9);

    const SHIRYOINUWETHPair = await Fetcher.fetchPairData(SHIRYOINU, WETH[1], ethersProvider);
    const route = new Route([SHIRYOINUWETHPair], WETH[1]);

    let tokenInEth = route.midPrice.invert().toSignificant(6);
    tokenInUSD = ETHPrice * tokenInEth;
  } catch (error) {
    console.log(error);
  }

  return tokenInUSD;
};
