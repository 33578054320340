import React, { useState, useEffect } from "react";
import { Container, Paper, Typography, Grid, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useInterval } from "../hooks/useInterval";

import { baseTokenContract, cardsContract } from "../contracts/ContractProvider";


const commaFormat = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default function Dashboard(props) {
  const CONTRACT_POLLING_INTERVAL = 5000; // millis

  const [loading, setLoading] = useState(true);
  const [ownedTokens, setOwnedTokens] = useState(0);

  const [cardsCollected, setCardsCollected] = useState(0);


  const fetchData = async () => {
    ////console.log(props)
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    let res = await baseTokenContract.methods.balanceOf(props.account).call();
    ////console.log(res)

    // token has 9 decimals
    if (res) {
      const numTokens = res * 10 ** -9;
      ////console.log(numTokens)
      setOwnedTokens(parseInt(numTokens));
    }

    let cardBalances = await cardsContract.methods
      .balancesOf(props.account)
      .call();

    if (cardBalances) {
      let collected = 0;

      cardBalances.forEach(element => {
        let balance = parseInt(element);
        if (balance > 0) collected += 1;
      });
      console.log(collected)
      setCardsCollected(collected);
    }

  };

  useInterval(() => {
    try {
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, CONTRACT_POLLING_INTERVAL);

  useEffect(() => {
    fetchData();
    setLoading(false);
    return () => {
      setOwnedTokens(0);
      setLoading(true);
    };
  }, []);

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {!loading && (
        <Paper
          elevation={3}
          sx={{
            p: 5,
            backgroundImage: "url(Dashboard_Header_01.jpeg)",
            backgroundSize: "auto",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography variant="h1">Your dashboard</Typography>
              <Typography variant="h3" sx={{ pt: 5 }}>
                Cards collected
              </Typography>
              <Grid container spacing={2} sx={{ pt: 1 }} alignItems="center">
                <Grid item>
                  <Typography variant="subtitle1">{cardsCollected} of 175</Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    component={NavLink}
                    to="/mint"
                  >
                    BUY MORE
                  </Button>
                </Grid>
              </Grid>
              <Typography variant="h3" sx={{ pt: 3 }}>
                SHIRYO TOKENS
              </Typography>
              <Grid container spacing={2} sx={{ pt: 1 }} alignItems="center">
                <Grid item>
                  <Typography variant="subtitle1">
                    {commaFormat(ownedTokens)}
                  </Typography>
                </Grid>
                <Grid item>
                  <a
                    href="https://swap.shiryo.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Button color="secondary" variant="contained" size="small">
                      BUY MORE
                    </Button>
                  </a>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ pt: 5 }} alignItems="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    component={NavLink}
                    to="/play"
                  >
                    PLAY
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    component={NavLink}
                    to="/mint"
                  >
                    MINT PACKS
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
      <Grid container sx={{ pt: 3 }} spacing={2}>
        <Grid item xs={12} sm={4} component={NavLink} to="/mint">
          <img src="Dashboard_CTA_01.png" alt="" width="100%" />
        </Grid>
        <Grid item xs={12} sm={4} component={NavLink} to="/market">
          <img src="Dashboard_CTA_03.png" alt="" width="100%" />
        </Grid>
        <Grid item xs={12} sm={4} component={NavLink} to="/my-packs">
          <img src="Dashboard_CTA_02.png" alt="" width="100%" />
        </Grid>
      </Grid>
    </Container>
  );
}
