import React, { useState, useEffect } from "react";
import {
  Paper,
  Stack,
  Grid,
  Typography,
  MenuItem,
  Button,
  CircularProgress,
  Menu,
  Box,
  FormControl,
  InputLabel,
  Select,
  TextField
} from "@mui/material";

import Launch from "@mui/icons-material/LaunchRounded";

import Container from "@mui/material/Container";
import { NavLink } from "react-router-dom";
import Pack from "../Components/Pack";
import { makeStyles } from "@mui/styles";

import ConnectedWeb3Provider from "../wallet/ConnectedWeb3Provider";

import {
  founderPackContract,
  normalPackContract,
  founderPackAddress,
  normalPackAddress,
  bridgeContractAddress,
  cardsContract,
} from "../contracts/ContractProvider";
import { useInterval } from "../hooks/useInterval";
import {
  cardsContractABI,
  IERC20,
  bridgeContractABI,
} from "../contracts/contractABI";
import { useTransactionMonitor } from "../hooks/useTransactionMonitor";
import OpenPack from "./OpenPack";

const commaFormat = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const useStyles = makeStyles({
  select: {
    "&&": { padding: "0px" },
  },
});

export default function PackBridge(props) {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [items, setItems] = useState([]);
  const [revealed, setRevealed] = useState(false);

  //const [packs, setPacks] = useState(0);
  const [founderPacks, setFounderPacks] = useState(0);
  const [normalPacks, setNormalPacks] = useState(0);

  const [amountFounderPacks, setAmountFounderPacks] = useState(1);
  const [amountNormalPacks, setAmountNormalPacks] = useState(1);

  const [founderNeedApproval, setFounderNeedsApproval] = useState(true);
  const [normalNeedsApproval, setNormalNeedsApproval] = useState(true);

  const CONTRACT_POLLING_INTERVAL = 3000; // millis
  const classes = useStyles();

  const { monitorTransaction } = useTransactionMonitor();
  const web3I = ConnectedWeb3Provider.web3; // use the connected wallet (this is my personal trick, there is probably a better way)

  const bridgePacks = async (_contract, _amount) => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

      console.log("Attempting to bridge: " + _amount)
     // _amount = 1; // hard coded for live testing

    const BridgeContract = new web3I.eth.Contract(
      bridgeContractABI,
      bridgeContractAddress
    ); // create a writeable version of the contract

      try {


        if (_contract==normalPackAddress){

          const estimatedGas = await BridgeContract.methods
          .bridgeNormalPacks(_amount)
          .estimateGas({ from: props.account });
        //console.log(estimatedGas);

        await BridgeContract.methods
          .bridgeNormalPacks(_amount)
          .send(
            { from: props.account, gas: estimatedGas + 500 },
            (error, txHash) => {
              if (!error) {
                monitorTransaction(error, txHash, async () => {
                  await bridgeDone();
                });
              }
            }
          )
          .catch((err) => {console.log(err)});
        }

        if (_contract==founderPackAddress){

          const estimatedGas = await BridgeContract.methods
          .bridgeFounderPacks(_amount)
          .estimateGas({ from: props.account });
        //console.log(estimatedGas);

        await BridgeContract.methods
          .bridgeFounderPacks(_amount)
          .send(
            { from: props.account, gas: estimatedGas + 500 },
            (error, txHash) => {
              if (!error) {
                monitorTransaction(error, txHash, async () => {
                  await bridgeDone();
                });
              }
            }
          )
          .catch((err) => {console.log(err)});
        }

        


      } catch (error) {
        console.log(error);
      }
  
  };

  const bridgeDone = async () => {};

  const onApproveClicked = async (_address) => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    //console.log("approving " + _address);

    const TokenContractWrite = new web3I.eth.Contract(IERC20, _address);
    const amount =
      "115792089237316195423570985008687907853269984665640564039457584007913129639935";
    //const amountWei = web3.utils.toWei(amount, "ether");

    const estimatedGas = await TokenContractWrite.methods
      .approve(bridgeContractAddress, amount)
      .estimateGas({ from: props.account });

    const result = TokenContractWrite.methods
      .approve(bridgeContractAddress, amount)
      .send({ from: props.account, gas: estimatedGas }, (error, txHash) => {
        if (!error) {
          monitorTransaction(error, txHash, async () => {});
        }
      })
      .catch(() => {});
    return result;
  };

  useInterval(() => {
    try {
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, CONTRACT_POLLING_INTERVAL);

  const fetchData = async () => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    try {
      if (founderNeedApproval === true) {
        let founderAllowance = await founderPackContract.methods
          .allowance(props.account, bridgeContractAddress)
          .call();
        if (parseInt(founderAllowance) === 0) {
          setFounderNeedsApproval(true);
        } else {
          setFounderNeedsApproval(false);
        }
      }

      if (normalNeedsApproval === true) {
        let normalAllowance = await normalPackContract.methods
          .allowance(props.account, bridgeContractAddress)
          .call();
        if (parseInt(normalAllowance) === 0) {
          setNormalNeedsApproval(true);
        } else {
          setNormalNeedsApproval(false);
        }
      }

      let erc20FounderPacks = await founderPackContract.methods
        .balanceOf(props.account)
        .call();
      let erc20NormalPacks = await normalPackContract.methods
        .balanceOf(props.account)
        .call();

      setFounderPacks(parseInt(erc20FounderPacks));
      setNormalPacks(parseInt(erc20NormalPacks));

      if (loading==true){
        setAmountFounderPacks(parseInt(erc20FounderPacks))
        setAmountNormalPacks(parseInt(erc20NormalPacks))
      }

    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setLoading(false);
      setFounderPacks(0);
      setNormalPacks(0);
    };
  }, []);

  return (
    <Container maxWidth="lg" sx={{ my: 3, pt: 3 }}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h1">
            Pack Bridge
            <a
              style={{ "text-decoration": "none" }}
              href="https://polygon.app.shiryo.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ marginLeft: "8px", marginTop: "4px" }}
                height="34"
                src="./polygon.png"
              />
            </a>
          </Typography>
          <p />
          <Typography variant="subtitle1">
            Bridge your unopened packs to the
            <a
              style={{ "text-decoration": "none" }}
              href="https://polygon.app.shiryo.com/"
              target="_blank"
              rel="noreferrer"
            >
              &nbsp; Polygon Network.
            </a>{" "}
            It can take some minutes for your packs to arrive.
            <p />
            Please be patient.
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper
            variant="outlined"
            sx={{ p: 3, mt: 3, background: "transparent" }}
          >
            <Stack>
              <Grid item sx={{ textAlign: "center" }}>
                <Typography variant="h3">
                  SHIRYO COLLECTION - FOUNDERS EDITION
                </Typography>
                {props.account && (
                  <Typography variant="subtitle1">
                    {founderPacks} UNOPENED
                  </Typography>
                )}
              </Grid>
              <Grid item sx={{ p: 5 }}>
                {/* <img style={{ width: "100%", padding: "16px" }} src="./founders.png" alt="SHIRYO COLLECTION - FOUNDERS EDITION" /> */}
                <Pack
                  model={
                    "https://shiryo-media.s3.eu-west-1.amazonaws.com/founders_new.glb"
                  }
                />
              </Grid>
              {props.account && (
                <Grid item>
                  <>
                    {props.account && loading ? (
                      <Grid item sx={{ textAlign: "center", py: 1 }}>
                        <CircularProgress size={24} />
                      </Grid>
                    ) : (
                      <>
                        <Grid container width="100%" sx={{ pt: 3 }}>
                          <Grid item xs={5}>
                            {founderNeedApproval ? (
                              <Button
                                sx={{ width: "100%" }}
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  onApproveClicked(founderPackAddress)
                                }
                              >
                                APPROVE
                              </Button>
                            ) : (
                              <Button
                                sx={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                disabled={founderPacks === 0}
                                onClick={() =>
                                  bridgePacks(founderPackAddress, amountFounderPacks)
                                }
                              >
                                BRIDGE NOW
                              </Button>
                            )}
                          </Grid>
                          <Grid item xs={7}>
                            <Box
                              sx={{
                                background: "black",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid #2B43C2",
                              }}
                            >
                              {props.account && founderPacks >= 1 ? (
                                <TextField
                                sx={{ width: "100%" }}
                                type="number"
                                variant="standard"
                                inputProps={{
                                  style: { textAlign: 'center' }, // the change is here
                                  inputMode: "numeric",
                                   min: 1, max: founderPacks 
                                }}
                                value={amountFounderPacks}
                                onChange={(e)=>{
                                   setAmountFounderPacks(e.target.value)
                                }}
                              />
                              ) : (
                                <Typography variant="subtitle1">
                                  {/* {founderPacks}{" "}
                      {founderPacks === 1 ? "PACK" : "PACKS"} UNOPENED */}
                                  0 PACKS
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                </Grid>
              )}
            </Stack>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper
            variant="outlined"
            sx={{ p: 3, mt: 3, background: "transparent" }}
          >
            <Stack>
              <Grid item sx={{ textAlign: "center" }}>
                <Typography variant="h3">SHIRYO COLLECTION - BASE</Typography>
                {props.account && (
                  <Typography variant="subtitle1">
                    {normalPacks} UNOPENED
                  </Typography>
                )}
              </Grid>
              <Grid item sx={{ p: 5 }}>
                {/* <img style={{ width: "100%", padding: "16px" }} src="./normal.png" alt="SHIRYO COLLECTION - BASE" /> */}
                <Pack
                  model={
                    "https://shiryo-media.s3.eu-west-1.amazonaws.com/normal_new.glb"
                  }
                />
              </Grid>
              {props.account && (
                <Grid item>
                  <>
                    {props.account && loading ? (
                      <Grid item sx={{ textAlign: "center", py: 1 }}>
                        <CircularProgress size={24} />
                      </Grid>
                    ) : (
                      <>
                        <Grid container width="100%" sx={{ pt: 3 }}>
                          <Grid item xs={5}>
                            {normalNeedsApproval ? (
                              <Button
                                sx={{ width: "100%" }}
                                variant="outlined"
                                color="primary"
                                onClick={() =>
                                  onApproveClicked(normalPackAddress)
                                }
                              >
                                APPROVE
                              </Button>
                            ) : (
                              <Button
                                sx={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                disabled={normalPacks === 0}
                                onClick={() =>
                                  bridgePacks(normalPackAddress, amountNormalPacks)
                                }
                              >
                                BRIDGE NOW
                              </Button>
                            )}
                          </Grid>
                          <Grid item xs={7}>
                            <Box
                              sx={{
                                background: "black",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                border: "1px solid #2B43C2",
                              }}
                            >
                              {props.account && normalPacks >= 1 ? (
                               <TextField
                               sx={{ width: "100%" }}
                               type="number"
                               variant="standard"
                               inputProps={{
                                 style: { textAlign: 'center' }, // the change is here
                                 inputMode: "numeric",
                                  min: 1, max: normalPacks 
                               }}
                               value={amountNormalPacks}
                               onChange={(e)=>{
                                  setAmountNormalPacks(e.target.value)
                               }}
                             />
                              ) : (
                                <Typography variant="subtitle1">
                                  {/* {founderPacks}{" "}
                      {founderPacks === 1 ? "PACK" : "PACKS"} UNOPENED */}
                                  0 PACKS
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                </Grid>
              )}
            </Stack>
          </Paper>
        </Grid>
      </Grid>
      {revealed && <OpenPack open={open} setOpen={setOpen} cards={items} />}
    </Container>
    // <Container>
    //   <Grid container justifyContent="space-between">
    //     <Grid item>
    //       <Typography variant="h1">My packs</Typography>
    //     </Grid>
    //     <Grid item>
    //       <Stack spacing={2} direction="row">
    //         <Button variant="contained" component={NavLink} to="/play">
    //           PLAY
    //         </Button>
    //         <Button variant="contained" component={NavLink} to="/mint">
    //           MINT PACKS
    //         </Button>
    //       </Stack>
    //     </Grid>
    //   </Grid>
    //   <Grid container>
    //     <Grid item xs={12}>
    //       <Paper variant="outlined" sx={{ p: 3, mt: 3, background: "transparent" }}>
    //         <Stack>
    //           <Grid item>
    //             <Typography variant="h3">Unopened Packs</Typography>
    //           </Grid>
    //           <Grid container spacing={2} sx={{ pt: 1 }} alignItems="flex-end">
    //             <Grid item>
    //               {loading ? (
    //                 <Typography variant="subtitle1">Accessing...</Typography>
    //               ) : (
    //                 <>
    //                   <Typography variant="subtitle1">Total: {unopened}</Typography>
    //                   <Typography variant="subtitle1" sx={{ pt: 1 }}>
    //                     Founder Packs: {numberFounderPacks}
    //                   </Typography>
    //                 </>
    //               )}
    //             </Grid>
    //             <Grid item>
    //               {/*
    //               <Button color="secondary" variant="contained" size="small">
    //                 BUY MORE
    //               </Button>
    //               */}
    //             </Grid>
    //           </Grid>
    //         </Stack>
    //       </Paper>
    //     </Grid>
    //   </Grid>

    //   {!loading && (
    //     <Grid container spacing={1} sx={{ pt: 3 }} justifyContent="space-between">
    //       {packs}
    //     </Grid>
    //   )}
    // </Container>
  );
}
